import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { clearError } from '@store/ui/error/actions'
import { BridgeService } from '@services/bridge'
import { ApplicationState } from '@store/types'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { validaEnvHomeByBridge } from '@utils/functions'
import Tag from '@utils/Tags'
import { sendTrackings } from '@utils/tracking'
import { FontesPagamento } from '@utils/enums'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'

import { Header } from './styles'
import { connector, Props } from './types'

const ErrorMessage = ({
  showError = false,
  actionCallback,
  block,
  dispatch,
  functionCallback,
}: Props) => {
  const [open, setOpen] = useState(showError)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { covenant } = useSelector((state: ApplicationState) => state.session)
  const { fonteSelecionada } = useSelector((state: ApplicationState) => state.novo.fontePagamento)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  useEffect(() => {
    if (open) {
      if (Number(fonteSelecionada) === FontesPagamento.INSS) {
        sendTrackings({
          name: `NOVO_${covenant}_FLUXO`,
          ref_figma: '2',
          screen: StepConsignadoTracking.ERRO_DIALOG,
          ref_type: 'Feedback',
          content_action: 'Dado carregado',
          element_action: 'page_loaded',
          action_id: 'Estamos resolvendo um erro',
        })
      } else {
        Tag.simuladorConsignadoTag({
          name: 'CONSIGNADO_FLUXO',
          ref_figma: '7',
          content_name: 'Estamos resolvendo um erro',
          tela: 'Erro dialog',
          content_action: 'Dado carregado',
          ref_type: 'Feedback',
          content_conv: covenant,
          product: covenant
            ? `CONSIGNADO_SIMULACAO_${covenant.toUpperCase()}`
            : 'CONSIGNADO_SIMULACAO',
        })
      }
      BridgeService.tac({
        name: 'mensagem_erro',
        isError: true,
        actions: ['simular_consignado', 'visualizar_termo', 'continuar_simulacao'],
        params: {
          covenant,
        },
      })
    }
  }, [covenant, fonteSelecionada, open])

  if (open) {
    return (
      <BottomSheet
        onClose={
          !block
            ? () => {
                setOpen(false)
                setTimeout(() => {
                  dispatch(clearError())
                }, 600)
              }
            : () => null
        }
      >
        <Header>
          <Text variant="headline-h3" color="typography" semiBold>
            Estamos resolvendo um erro
          </Text>
        </Header>
        <Text variant="body-3">
          Mas fique tranquilo, em breve você poderá continuar o seu processo.
        </Text>

        <Button
          fullWidth
          style={{ marginTop: '16px' }}
          onClick={() => {
            if (Number(fonteSelecionada) === FontesPagamento.INSS) {
              sendTrackings({
                name: `NOVO_${covenant}_FLUXO`,
                ref_figma: '2',
                screen: StepConsignadoTracking.ERRO_DIALOG,
                ref_type: 'Botao',
                content_action: 'Toque',
                element_action: 'click button',
                action_id: 'Tentar novamente',
              })
            } else {
              Tag.simuladorConsignadoTag({
                name: 'CONSIGNADO_FLUXO',
                ref_figma: '8',
                content_name: 'Tentar novamente',
                tela: 'Erro dialog',
                content_action: 'Toque',
                ref_type: 'Botao',
                content_conv: covenant,
                product: covenant
                  ? `CONSIGNADO_SIMULACAO_${covenant.toUpperCase()}`
                  : 'CONSIGNADO_SIMULACAO',
              })
            }
            setOpen(false)
            setTimeout(() => {
              dispatch(clearError())
              if (actionCallback instanceof Function) {
                dispatch(actionCallback())
              } else if (functionCallback instanceof Function) {
                functionCallback()
              }
            }, 600)
          }}
        >
          Tentar novamente
        </Button>
        <Button
          fullWidth
          variant="secondary"
          style={{ marginTop: '16px' }}
          onClick={() => {
            if (Number(fonteSelecionada) === FontesPagamento.INSS) {
              sendTrackings({
                name: `NOVO_${covenant}_FLUXO`,
                ref_figma: '2',
                screen: StepConsignadoTracking.ERRO_DIALOG,
                ref_type: 'Botao',
                content_action: 'Toque',
                element_action: 'click button',
                action_id: 'Voltar para home',
                redirect_url: validaEnvHomeByBridge(isApp),
              })
            } else {
              Tag.simuladorConsignadoTag({
                name: 'CONSIGNADO_FLUXO',
                ref_figma: '8',
                content_name: 'Voltar para home',
                tela: 'Erro dialog',
                content_action: 'Toque',
                ref_type: 'Botao',
                content_conv: covenant,
                product: covenant
                  ? `CONSIGNADO_SIMULACAO_${covenant.toUpperCase()}`
                  : 'CONSIGNADO_SIMULACAO',
              })
            }
            setOpen(false)
            window.location.href = validaEnvHomeByBridge(isApp) || ''
          }}
        >
          Voltar para home
        </Button>
      </BottomSheet>
    )
  }
  return <></>
}

export default connector(ErrorMessage)
