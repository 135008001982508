/**
 * Interface referente aos trackings de APP/WEB/Consignado
 */
export interface ITrackingProps {
  /**
   * Nome do tracking no figma, ex: NOVO_INSS_FLUXO
   */
  name: string
  /**
   * Número do tracking no figma
   */
  ref_figma: '1' | '2' | '3'
  /**
   * screen/tela/step -> nome da tela
   */
  screen: StepConsignadoTracking
  /**
   * Tipo do item -> Botao, Feedback, Bottomsheet, Select, Slide, etc.
   */
  ref_type: string
  /**
   * Ação -> Toque ou Dado carregado (APP)
   */
  content_action: string
  /**
   * Ação -> click button, page_loaded, bottomsheet (WEB)
   */
  element_action: ElementAction
  /**
   * Nome que disparou ação, nome do botão ou título da mensagem carregada
   */
  action_id: string
  /**
   * Novo, Refinanciamento ou Portabilidade
   */
  product?: string
  /**
   * Url a ser redirecionada no fluxo (WEB)
   */
  redirect_url?: string
  /**
   * Campanha de onde veio (APP)
   */
  utm_campaign?: string
  /**
   * Chamar função para enviar track de consignado,
   * opcional pois nem todo tracking precisa ser passado pra o consignado
   */
  callConsignadoTrack?: (step: StepConsignadoTracking) => void
  /**
   * Params de input (WEB)
   */
  params_input?: IDataLayerParamsInput
}

export type ElementAction =
  | 'click button'
  | 'click link'
  | 'click select'
  | 'bottomsheet'
  | 'click dropdown'
  | 'submit'
  | 'click banner'
  | 'click dialog'
  | 'click video'
  | 'click scroll'
  | 'click menu'
  | 'page_loaded'

export interface IDataLayerParamsInput {
  input_name_1?: string | number | undefined | boolean
  input_value_1?: string | number | undefined | boolean
  input_name_2?: string | number | undefined | boolean
  input_value_2?: string | number | undefined | boolean
  input_name_3?: string | number | undefined | boolean
  input_value_3?: string | number | undefined | boolean
  input_name_4?: string | number | undefined | boolean
  input_value_4?: string | number | undefined | boolean
  input_name_5?: string | number | undefined | boolean
  input_value_5?: string | number | undefined | boolean
  input_name_6?: string | number | undefined | boolean
  input_value_6?: string | number | undefined | boolean
  input_name_7?: string | number | undefined | boolean
  input_value_7?: string | number | undefined | boolean
  input_name_8?: string | number | undefined | boolean
  input_value_8?: string | number | undefined | boolean
  input_name_9?: string | number | undefined | boolean
  input_value_9?: string | number | undefined | boolean
}

export enum StepConsignadoTracking {
  HOME_CONSIGNADO = 'Home Consignado',
  TERMO_INSS = 'Termo INSS',
  DADOS_PESSOAIS = 'Dados pessoais',
  // Se o usuario clicar pra ver o termo
  LEITURA_TERMO = 'Leitura Termo',
  VALIDACAO_EMAIL = 'Validacao email',
  CODIGO_EMAIL = 'Codigo email',
  VALIDACAO_TELEFONE = 'Validacao telefone',
  CODIGO_TELEFONE = 'Codigo telefone',
  DESQUALIFICACAO = 'Desqualificacao',
  ERRO_SISTEMICO = 'Erro sistemico',
  SIMULADOR = 'Simulador',
  EVASAO_SIMULADOR = 'Evasao do simulador',
  RESUMO = 'Resumo',
  ERRO_DIALOG = 'Erro dialog',
}
