import { format } from 'date-fns'

import { SimulacaoNovoRequest } from '@services/postSimulacaoNovo'
import { NovoState } from '@store/novo'
import {
  allNamesAfterFirstName,
  calcDataPrimeiroDesconto,
  datePtToEn,
  FamiliaEnum,
  FamiliaType,
  firstName,
} from '@utils/simuladorUtils'
import { SessionState } from '@store/session/types'
import { UiState } from '@store/ui'
import { TipoEmprestimo } from '@utils/enums'
import { removeMask } from '@utils/masks'

export const buildPostSimulacao = (novoState: NovoState) => {
  const { familia } = novoState.simulador
  const isFamiliaPrivada = familia === FamiliaEnum.EMPRESA
  const objetoSimulacao: SimulacaoNovoRequest = {
    codigoAreaAcesso: '1',
    codigoConvenio: isFamiliaPrivada
      ? 47
      : Number(novoState.fontePagamento.convenioSelecionado) || 0,
    descConvenio: isFamiliaPrivada
      ? ''
      : novoState.fontePagamento?.convenios?.find(
          (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
        )?.descricao || '',
    codigoOrgao: isFamiliaPrivada ? 50 : Number(novoState.fontePagamento?.orgaoSelecionado) || 0,
    descOrgao: isFamiliaPrivada
      ? ''
      : novoState.fontePagamento?.orgaos?.find(
          (orgao) => orgao.codigo === Number(novoState.fontePagamento?.orgaoSelecionado),
        )?.descricao || '',
    cpf: removeMask(novoState.dadosPessoais?.cpf),
    dataNascimento: datePtToEn(novoState.dadosPessoais?.dataNascimento),
    email: novoState.codIndicacaoContato?.email,
    matricula: removeMask(String(novoState.situacaoFuncional?.matricula || '')),
    nome: novoState.dadosPessoais?.nome,
    origem: 0,
    qtdParcelas: novoState.simulador?.parcelas,
    mostrarTodasParcelas: true,
    salario: isFamiliaPrivada
      ? Number(novoState.dadosProfissionais?.salario)
      : Number(novoState.situacaoFuncional?.salario),
    telefone: removeMask(novoState.codIndicacaoContato?.telefone),
  }
  if (novoState.simulador.isTotal) {
    objetoSimulacao.valorSolicitacao =
      novoState.simulador?.novoValorTotal !== 0
        ? novoState.simulador.novoValorTotal
        : novoState.simulador.valorTotal
  } else {
    objetoSimulacao.valorSolicitacao = novoState.simulador?.valorTotal
  }

  return objetoSimulacao
}

const verificarConvenioDescricao = (novoState: NovoState) =>
  novoState.fontePagamento?.convenios?.find(
    (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
  )?.descricao || ''

const verificarOrgaoDescricao = (novoState: NovoState) =>
  novoState.fontePagamento?.orgaos?.find(
    (orgao) => orgao.codigo === Number(novoState.fontePagamento?.orgaoSelecionado),
  )?.descricao || ''

export const buildSalvarSimulacao = (
  novoState: NovoState,
  session: SessionState,
  ui: UiState,
  familia: FamiliaType,
) => {
  const isFamiliaPrivada = familia === FamiliaEnum.EMPRESA
  const tipoSimulacao = novoState.simulacao?.simulacaoSelecionada
  const tipoSimulacoes = novoState.simulacao?.simulacoesSelecionada
  const simulationData = !ui.navigation.newSimulatorApi
    ? novoState?.simulacao[tipoSimulacao]
    : novoState?.simulacao.parcelaAtual

  const verificarCreditoProtegido = () => {
    if (ui?.navigation.newSimulatorApi) {
      return tipoSimulacoes === TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
    }
    return tipoSimulacao === TipoEmprestimo.CREDITO_PROTEGIDO
  }

  return {
    autarquiaEmpresa: isFamiliaPrivada ? '' : verificarOrgaoDescricao(novoState),
    autorizaBancoConsulta: true,
    campanha: session.campanha,
    cartaoConsignado: false,
    clienteId: session?.sessionId || '',
    convenio: isFamiliaPrivada ? '' : verificarConvenioDescricao(novoState),
    convenioId: isFamiliaPrivada ? '47' : String(novoState.fontePagamento?.convenioSelecionado),
    cpf: removeMask(novoState.dadosPessoais?.cpf) || '',
    creditoProtegido: verificarCreditoProtegido(),
    dataNascimento: datePtToEn(novoState.dadosPessoais?.dataNascimento),
    dataPrimeiroDesconto: calcDataPrimeiroDesconto(simulationData),
    dataSimulacao: format(new Date(), 'yyyy-MM-dd hh:mm:sss'),
    dataUltimoDesconto: simulationData.dataUltimoVencimento,
    email: novoState.codIndicacaoContato?.email || '',
    familia,
    idOrgao: isFamiliaPrivada ? '50' : novoState.fontePagamento?.orgaoSelecionado || '',
    margem: '',
    matricula: isFamiliaPrivada ? '' : removeMask(String(novoState.situacaoFuncional?.matricula)),
    nomeEmpresa: isFamiliaPrivada ? novoState.dadosProfissionais.nomeEmpresa : '',
    numeroParcelasFinal: simulationData?.qtdeParcelas,
    numeroSessao: 0,
    origem: ui.navigation.isApp ? 'App' : 'Site',
    primeiroNome: firstName(novoState.dadosPessoais?.nome),
    nomeSocial: novoState.dadosPessoais?.nomeSocial || '',
    qtdParcelas: simulationData?.qtdeParcelas,
    salario: isFamiliaPrivada
      ? Number(novoState.dadosProfissionais.salario)
      : Number(novoState.situacaoFuncional?.salario) || 0,
    taxa: simulationData?.taxaNominalAm,
    telefone: removeMask(novoState.codIndicacaoContato?.telefone) || '',
    tempoEmpresa: isFamiliaPrivada ? novoState.dadosProfissionais?.tempoEmpresaSelecionado : '',
    tipo: isFamiliaPrivada ? 'Crédito Consignado Privado' : 'Crédito Consignado Público',
    tipoSimulacao: novoState.simulador?.isTotal ? 1 : 2,
    ufConvenio:
      novoState.fontePagamento?.convenios?.find(
        (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
      )?.estado || 'MG',
    ultimoNome: allNamesAfterFirstName(novoState.dadosPessoais?.nome),
    valorCET: simulationData?.cETAa,
    valorCartaoConsignado: novoState.simulacao?.limiteCartao,
    valorEmprestimo: simulationData?.valorLiberado,
    valorIOF: simulationData?.valorIOF,
    valorLiberado: simulationData?.valorLiberado,
    valorParcela: simulationData.valorParcela,
    valorParcelaFinal: simulationData?.valorParcela,
    valorSolicitado: simulationData.valorSolicitado,
    valorTaxaJuros: simulationData?.taxaNominalAm,
    valorTotalFinal: simulationData?.valorTotalASerPago,
    flagTitularDados: novoState.dadosPessoais.flagTitularDados,
    flagInfoCredito: novoState.codIndicacaoContato.flagInfoCredito,
    utmCampaign: String(session?.utmCampaign) || '',
    utmContent: String(session?.utmContent) || '',
    utmMedium: String(session?.utmMedium) || '',
    utmSource: String(session?.utmSource) || '',
  }
}
