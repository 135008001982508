import { FamiliaType } from '@utils/simuladorUtils'
import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarSimulacaoRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_SIMULACAO_SALVAR
    : process.env.REACT_APP_SIMULACAO_SALVAR_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ResponseSalvarSimulacao>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SalvarSimulacaoRequest {
  autarquiaEmpresa: string
  autorizaBancoConsulta: boolean
  campanha: string
  cartaoConsignado: boolean
  clienteId: string
  convenio: string
  convenioId: string
  cpf: string
  creditoProtegido: boolean
  dataNascimento: string
  dataPrimeiroDesconto: string
  dataSimulacao: string
  dataUltimoDesconto: string
  email: string
  familia: FamiliaType
  idOrgao: string | number
  margem: string
  matricula: string
  nomeEmpresa: string
  numeroParcelasFinal: number
  numeroSessao: number
  origem: string
  primeiroNome: string
  nomeSocial: string
  qtdParcelas: number
  salario: number
  taxa: number
  telefone: string
  tempoEmpresa: string | number
  tipo: string
  tipoSimulacao: number
  ultimoNome: string
  valorCET: number
  valorCartaoConsignado: number
  valorEmprestimo: number
  valorIOF: number
  valorLiberado: number
  valorParcela: number
  valorParcelaFinal: number
  valorSolicitado: number
  valorTaxaJuros: number
  valorTotalFinal: number
  flagInfoCredito?: boolean
  flagTitularDados?: boolean
}

export interface ResponseSalvarSimulacao {
  data: {
    idSimulacao: string | number | undefined
  }
}
