import React from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import routesV3 from '@routes/SimuladorV3/routes'
import ArrowLeft from '@interco/icons/core/action-navigation/ic_arrow_left'
import Help from '@interco/icons/core/status/ic_help_circle'
import { ApplicationState } from '@store/types'
import { validaEnvHomeByBridge } from '@utils/functions'
import { BridgeService } from '@services/bridge'
import Tag from '@utils/Tags'
import { setShowBottomsheetEvasao } from '@store/ui/navigation/actions'

import * as S from './styles'

export type HeaderProps = {
  children: React.ReactNode
  rightIcon?: 'house' | 'download' | 'more' | 'question-mark' | React.ReactNode
  onRightButtonClick?: () => void
  showLeftIcon?: boolean
  showRightIcon?: boolean
  headerPosition?: string
}

type RightIconProps = Pick<HeaderProps, 'rightIcon' | 'onRightButtonClick'>

const RightIcon = ({ rightIcon, onRightButtonClick }: RightIconProps): JSX.Element => {
  if (typeof rightIcon === 'string') {
    if (rightIcon === 'question-mark')
      return (
        <Help
          color="var(--primary500)"
          onClick={() => onRightButtonClick?.()}
          width={24}
          height={24}
        />
      )
  }

  if (rightIcon) return rightIcon as JSX.Element
  return <S.CleanIcon />
}

export const Header = ({
  children,
  rightIcon,
  onRightButtonClick,
  showLeftIcon = true,
  showRightIcon = false,
  headerPosition = '',
}: HeaderProps) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { isApp, origemGoBack } = useSelector((state: ApplicationState) => state.ui.navigation)

  const onBackClick = () => {
    if (location?.pathname === routesV3.HOME) {
      if (
        origemGoBack === 'emprestimo' ||
        origemGoBack === 'emprestimoinss' ||
        origemGoBack === 'emprestimosanguelaranja'
      ) {
        window.location.href = validaEnvHomeByBridge(isApp) || ''
      } else {
        BridgeService.backToNative()
      }
    } else if (location?.pathname === routesV3.RESULTADO_SIMULACAO) {
      dispatch(setShowBottomsheetEvasao(true))
    } else {
      Tag.simuladorConsignadoTag({
        ref_figma: '6',
        name: 'HEADER',
        content_action: 'Toque',
        action_id: 'back',
        product: `CONSIGNADO_SIMULACAO`,
      })
      history.goBack()
    }
  }

  return (
    <S.Container headerPosition={headerPosition}>
      {showLeftIcon && (
        <S.IconButton type="button" name="left-buttom" dir="left-button" onClick={onBackClick}>
          <ArrowLeft color="var(--primary500)" width={24} height={24} />
        </S.IconButton>
      )}
      <S.Title>{children}</S.Title>
      {showRightIcon && (
        <S.IconButton
          type="button"
          name="left-buttom"
          dir="left-button"
          onClick={onRightButtonClick}
        >
          <RightIcon rightIcon={rightIcon} />
        </S.IconButton>
      )}
    </S.Container>
  )
}
