import { call, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import trackingSimulacaoService, { TrackingSimulacaoRequest } from '@services/postTrackingSimulacao'
import { ConveniosCodes, StepNRNovo } from '@utils/enums'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'

import { buildTrackingSimulacao } from './models/modelNewRelicTracking'

export default function* postTrackingSimulacao(action: AnyAction) {
  const payload = action.payload as StepNRNovo | StepConsignadoTracking
  const stepConsignadoValues = Object.values(StepConsignadoTracking)
  const stepNRValues = Object.values(StepNRNovo)
  try {
    const { ui, session, novo } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)

    const trackingRequest: TrackingSimulacaoRequest = buildTrackingSimulacao(
      novo,
      session,
      ui,
      payload,
    )
    // TODO: remover esse artificio depois que o tracking novo for para o Siape também!
    // Se for inss E o step enviado tiver incluso nos valores da list de step novo OK
    // Se for da lista de step antigo, e diferente de inss OK
    // Garantindo que o inss não vai enviar o tracking antigo mais.
    if (
      stepConsignadoValues.includes(payload as StepConsignadoTracking) &&
      Number(novo.fontePagamento?.convenioSelecionado) === ConveniosCodes.INSS
    ) {
      yield* call(trackingSimulacaoService, trackingRequest, newSimulatorApi)
    } else if (
      stepNRValues.includes(payload as StepNRNovo) &&
      Number(novo.fontePagamento?.convenioSelecionado) !== ConveniosCodes.INSS
    ) {
      yield* call(trackingSimulacaoService, trackingRequest, newSimulatorApi)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('​error', error)
  }
}
