import { action, Action } from 'typesafe-actions'

import { SimulacaoNovoResponse } from '@services/postSimulacaoNovo'
import { StepNRNovo } from '@utils/enums'
import { StepConsignadoTracking } from '@utils/tracking/tracking.types'

import {
  ParcelaSimulacaoSangueLaranjaPayload,
  SalvarLeadRequestPayload,
  SalvarLeadSangueLaranjaRequestPayload,
  Simulacao,
  SimulacaoRequestPayload,
  SimulacaoSangueLaranjaRequestPayload,
  SimulacaoTypes,
} from './types'
import { PayloadAutorizacao } from './sagas/postAutorizacaoISafe'
import {
  PayloadExcluirProposta,
  PayloadSalvarContrato,
} from './sagas/postSalvarContratoSangueLaranja'

export const simulacaoRequest = (payload: SimulacaoRequestPayload): Action =>
  action(SimulacaoTypes.SIMULACAO_REQUEST, payload)

export const simulacaoSangueLaranjaRequest = (
  payload: SimulacaoSangueLaranjaRequestPayload,
): Action => action(SimulacaoTypes.SIMULACAO_SANGUE_LARANJA_REQUEST, payload)

export const simulacaoSuccess = (payload: SimulacaoNovoResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_SUCCESS, payload)

export const simulacaoExercitoSuccess = (payload: SimulacaoNovoResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_EXERCITO_SUCCESS, payload)

export const simulacaoError = (): Action => action(SimulacaoTypes.SIMULACAO_ERROR)
export const simulacaoSangueLaranjaError = (): Action =>
  action(SimulacaoTypes.SIMULACAO_SANGUE_LARANJA_ERROR)

export const selectSimulacao = (payload: string): Action =>
  action(SimulacaoTypes.SELECT_SIMULACAO, payload)

export const trackingSimulacao = (step: StepNRNovo | StepConsignadoTracking): Action =>
  action(SimulacaoTypes.TRACKING_SIMULACAO, step)

export const salvarLeadRequest = (payload: SalvarLeadRequestPayload): Action =>
  action(SimulacaoTypes.SALVAR_LEAD_REQUEST, payload)

export const salvarLeadSangueLaranjaRequest = (
  payload: SalvarLeadSangueLaranjaRequestPayload,
): Action => action(SimulacaoTypes.SALVAR_LEAD_SANGUE_LARANJA_REQUEST, payload)

export const salvarLeadSuccess = (): Action => action(SimulacaoTypes.SALVAR_LEAD_SUCCESS)

export const salvarLeadError = (): Action => action(SimulacaoTypes.SALVAR_LEAD_ERROR)

export const clearSimulacao = (): Action => action(SimulacaoTypes.CLEAR_SIMULACAO)

export const hasObjetoSimulacao = (payload: boolean): Action =>
  action(SimulacaoTypes.HAS_OBJETO_SIMULACAO, payload)

export const hasObjetoSimulacaoProtegida = (payload: boolean): Action =>
  action(SimulacaoTypes.HAS_OBJETO_SIMULACAO_PROTEGIDA, payload)

export const setValorMax = (payload: number): Action =>
  action(SimulacaoTypes.SET_VALOR_MAX, payload)

export const setIdSimulacao = (payload?: number | string): Action =>
  action(SimulacaoTypes.SET_SIMULACAO_ID, payload)

export const setIsContratacaoCompleta = (payload: boolean): Action =>
  action(SimulacaoTypes.SET_IS_CONTRATACAO_COMPLETA, payload)

export const setTotalParcelas = (payload?: number): Action =>
  action(SimulacaoTypes.SET_TOTAL_PARCELAS, payload)

export const setParcelaSelecionada = (payload: Simulacao): Action =>
  action(SimulacaoTypes.SET_PARCELA_SELECIONADA, payload)

export const selectSimulacoes = (payload: string): Action =>
  action(SimulacaoTypes.SELECT_SIMULACOES, payload)

export const setDataHoraDadosRecebidos = (payload: string): Action =>
  action(SimulacaoTypes.SET_DATA_HORA_DADOS_RECEBIDOS, payload)

export const setDadosSimulacaoAlterados = (payload: boolean): Action =>
  action(SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS, payload)

export const postIsafeAutorizacao = (payload: PayloadAutorizacao): Action =>
  action(SimulacaoTypes.POST_ISAFE_AUTORIZACAO, payload)

export const postSalvarContratoSangueLaranja = (payload: PayloadSalvarContrato): Action =>
  action(SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_REQUEST, payload)

export const postSalvarContratoSangueLaranjaSuccess = (): Action =>
  action(SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_SUCCESS)

export const putAtualizarContratoSangueLaranja = (payload: PayloadExcluirProposta): Action =>
  action(SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_REQUEST, payload)

export const putAtualizarContratoSangueLaranjaSuccess = (): Action =>
  action(SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_SUCCESS)

export const putAtualizarContratoSangueLaranjaError = (): Action =>
  action(SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_ERROR)

export const getParcelaSangueLaranja = (payload: ParcelaSimulacaoSangueLaranjaPayload): Action =>
  action(SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_REQUEST, payload)

export const getParcelaSangueLaranjaSuccess = (): Action =>
  action(SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_SUCCESS)

export const getParcelaSangueLaranjaError = (): Action =>
  action(SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_ERROR)
