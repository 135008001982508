import * as React from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import ProgressBarSteps from '@components/organims/ProgressBarSteps'
import { Header } from '@atoms/Header'
import { BridgeService } from '@services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'

import { Container, Content, ContainerFooter } from './styles'
import { PageProps } from './types'

const NovoPage = ({
  children,
  stickyFooter,
  footerTwoButtonsSpace = '200px',
  stickyContainerFooterBottom,
  positionFooter,
  style,
  rightIcon,
  onRightButtonClick,
  showLeftIcon,
  showRightIcon,
  titleHeader = 'Consignado Público',
  headerPosition = '',
}: PageProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!BridgeService.isBrowser()) {
    interWbSession.editToolbar('', false)
  }

  return (
    <>
      {isApp ? (
        <Header
          showLeftIcon={showLeftIcon}
          showRightIcon={showRightIcon}
          rightIcon={rightIcon}
          onRightButtonClick={onRightButtonClick}
          headerPosition={headerPosition}
        >
          {titleHeader}
        </Header>
      ) : (
        <></>
      )}
      <ProgressBarSteps />
      <Container id="page-container" style={style}>
        <Content isWeb={!isApp} id="page-content" footerTwoButtonsSpace={footerTwoButtonsSpace}>
          {children}
        </Content>
        {stickyFooter && (
          <ContainerFooter
            id="page-sticky-footer"
            stickyContainerFooterBottom={stickyContainerFooterBottom}
            positionFooter={positionFooter}
          >
            {stickyFooter}
          </ContainerFooter>
        )}
      </Container>
    </>
  )
}

export default NovoPage
