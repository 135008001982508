import { Reducer } from 'redux'

import { DadosPessoaisState, DadosPessoaisTypes } from './types'

const INITIAL_STATE: DadosPessoaisState = {
  nome: '',
  cpf: '',
  dataNascimento: '',
  perfil: undefined,
  renda: 0,
  flagTitularDados: true,
  conta: '',
  senhaServidor: '',
  sangueLaranja: false,
  numeroContrato: undefined,
  nomeSocial: '',
}

const reducer: Reducer<DadosPessoaisState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosPessoaisTypes.SET_NOME:
      return { ...state, nome: action.payload }
    case DadosPessoaisTypes.SET_CPF:
      return { ...state, cpf: action.payload }
    case DadosPessoaisTypes.SET_DATA_NASCIMENTO:
      return { ...state, dataNascimento: action.payload }
    case DadosPessoaisTypes.SET_PERFIL:
      return { ...state, perfil: action.payload }
    case DadosPessoaisTypes.SET_RENDA:
      return { ...state, renda: action.payload }
    case DadosPessoaisTypes.SET_FLAG_TITULAR_DADOS:
      return { ...state, flagTitularDados: action.payload }
    case DadosPessoaisTypes.SET_CONTA:
      return { ...state, conta: action.payload }
    case DadosPessoaisTypes.SET_SENHA_SERVIDOR:
      return { ...state, senhaServidor: action.payload }
    case DadosPessoaisTypes.SET_SANGUE_LARANJA:
      return { ...state, sangueLaranja: action.payload }
    case DadosPessoaisTypes.SET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA:
      return { ...state, ...action.payload }
    case DadosPessoaisTypes.SET_NUMERO_CONTRATO:
      return { ...state, numeroContrato: action.payload }
    case DadosPessoaisTypes.SET_NOME_SOCIAL:
      return { ...state, nomeSocial: action.payload }
    case DadosPessoaisTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
